<template>
  <div class="alerts-header">
      <v-container>
          
          <v-row>
              <v-col cols="4">
                    <h2 class="subtitle">Dernières alertes envoyées</h2>

                    <v-simple-table dense class="alert-list" v-if="lastAlerts.length > 0">
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th />
                                <th class="text-center">
                                    Date
                                </th>
                                <th class="text-center">
                                    Niveau
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                            v-for="alert in lastAlerts"
                            :key="alert.id"
                            >
                            <td><v-icon :color="colorByLevel(alert.level)">mdi-alert</v-icon></td>
                            <td>{{ localDate(alert.start_date) }}</td>
                            <td>{{ alert.level }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                    <p v-else>
                        Aucune alerte envoyée dans les 3 dernières heures
                    </p>
                </v-col>
                <v-spacer/>
              <v-col>
                  <!-- <v-row>
                            <v-col> -->
                                <h2 class="subtitle">Niveau d'alerte</h2>
                                <div class="d-flex">
                                    <v-sheet
                                        v-for="(color, index) in colors"
                                        :key="index"
                                        :color="color"
                                        :elevation="hasMarkerClass(index) ? 5 : 0"
                                        :height="hasMarkerClass(index) ? 50 : 35"
                                        :width="hasMarkerClass(index) ? 50 : 35"
                                        class="align-self-center alert-scale"        
                                    >
                                        <h3 class="alert-scale-item">{{ index }}</h3>

                                    </v-sheet>
                                </div>
                  
              </v-col>

                
          </v-row>
      </v-container>

        <div class="text-center ma-2">



    <v-snackbar
      v-model="alertSnackbar" :color="colors[this.currentAlert.level]" class="alert-snackbar"
      right top absolute
    >
      <h1>{{ alertMessage }}</h1>

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="alertSnackbar = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
  </div>
</template>

<script>
import ApiRepository from '@/ApiRepository';
import moment from 'moment';

  export default {
    components: {
    //   'v-notification': Notification,
    },
    data() {
      return {
        updateDate: 'Aucune donnée',
        allowNotifications: false,
        lastAlerts: [],
        notifications: {},
        firstLoad: true,
        colors: {
          0: '#109b00',
          1: '#d9c301',
          2: '#da6300',
          3: '#9b0700',
          4: '#000000',
        },
         alertSnackbar: false,
         currentAlert: {
            "level": 0
         },
         updateIntervalHolder: undefined
      };
    },
    computed: {
      alertMessage() {
          if (this.currentAlert.level === 0) {
            return `${this.updateDate} - Fin de l'alerte`;
          }
          return `${this.updateDate} - Niveau d'alerte: ${this.currentAlert.level}`;
      }
    },
    methods: {
        getCurrentAlert() {
            ApiRepository.getCurrentAlert()
                .then(result => {
                    this.updateDate = moment().format('DD/MM/YYYY HH:mm:ss');
                    const previousAlert = this.currentAlert;
                    this.currentAlert = result.data;
                    if (previousAlert.level !== this.currentAlert.level) {
                        this.triggerAlert();
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        },
        getLast3hAlerts() {
            ApiRepository.getLast3hAlerts()
                .then(result => {
                    this.lastAlerts = result.data.alerts;
                })
                .catch(error => {
                    console.log(error);
                })
        },
     requestNotificationPermission() {
        if (Notification.permission === 'granted') {
            this.allowNotifications = true;
        } else if (Notification.permission !== 'denied') {
            Notification.requestPermission()
                .then(value => {this.allowNotifications = value === 'granted' ? true : false});
        }
        },
      localDate(date) {
        if (date) {
          return moment.utc(date, 'YYYY/MM/DD HH:mm').local().format('DD/MM/YYYY HH:mm');
        }
        return 'Indisponible';
      },
      colorByLevel(level) {
        return this.colors[level];
      },
      hasMarkerClass(index) {
        return this.colorByLevel(this.currentAlert.level) === this.colors[index];
      },
      triggerAlert() {
          this.alertSnackbar = true;
            new Audio(require('@/assets/alert.mp3')).play()
                .then(() => {
                    console.log("// Audio is playing.");
                })
                .catch(error => {
                    console.log(error);
                });

        if (this.allowNotifications) {
            let notification = new Notification("Alerte pluie", {
                body: this.alertMessage,
                badge: require('@/assets/wm_logo.png'),
                tag: "alert-notification",
                renotify: true
            });

            notification.onclick = () => window.focus();
        }
      }
    },
    mounted() {
        this.getCurrentAlert();
        this.getLast3hAlerts();
        this.requestNotificationPermission();
        clearInterval(this.updateIntervalHolder);
        this.updateIntervalHolder = setInterval(() => {
            this.getCurrentAlert();
            this.getLast3hAlerts();
        }, 10000);
    },
    beforeDestroy() {
      clearInterval(this.updateIntervalHolder);
    },
  };
</script>

<style scoped>
    .alerts-header {
        margin: 80px 0 80px 0;
    }

  .alert-snackbar {
      margin-top: 100px
  }

  .alert-scale {
      display: flex;
      align-items: center;
      justify-content: center;
  }

  .alert-scale-item {
      color: white;
  }

</style>
