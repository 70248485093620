import axios from "axios";

export default {
    getAccessToken: (email, password) => {
        return axios.post(
            '/login/access-token', 
			new URLSearchParams({username: email, password}),
		);
    },
    getLastHourImages: () => {
        return axios.get(
            '/images/last_hour'
        )
    },
    getWatershedAlerts: () => {
        return axios.get(
            '/polygons/alerts'
        )
    },
    getCurrentAlert: () => {
        return axios.get(
            '/alerts/current'
        )
    },
    getLast3hAlerts: () => {
        return axios.get(
            '/alerts/last_3h'
        )
    },

    getUsers: () => {
        return axios.get(
            '/settings/users'
        )
    },
    createUser: (user) => {
        return axios.post(
            '/settings/user',
            user
        )
    },
    updateUser: (user) => {
        return axios.put(
            '/settings/user/' + user.id,
            user
        )
    },
    deleteUser: (user) => {
        return axios.delete(
            '/settings/users/' + user.id,
        )
    },
    getContacts: () => {
        return axios.get(
            '/settings/contacts'
        )
    },
    createContact: (contact) => {
        return axios.post(
            '/settings/contact',
            contact
        )
    },
    updateContact: (contact) => {
        return axios.put(
            '/settings/contact/' + contact.id,
            contact
        )
    },
    deleteContact: (contact) => {
        return axios.delete(
            '/settings/contact/' + contact.id,
        )
    },
    createAlert: (level) => {
        return axios.post(
            '/settings/alert',
            { level }
        )
    },
    getCameraImage: (cameraName) => {
        return axios.get(
            '/camera/image?camera=' + cameraName
        )
    },
};
