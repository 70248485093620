import Vue from 'vue';
import VueRouter from 'vue-router';
import App from './App.vue';

import vuetify from './plugins/vuetify';

// import Auth from "./Auth";

import Login from './pages/Login.vue';
import Home from './pages/Home.vue';
import Settings from './pages/Settings.vue';

import store from  './store';
import Axios from 'axios'

import 'leaflet/dist/leaflet.css';

Vue.config.productionTip = false;

Vue.use(VueRouter);

Vue.prototype.$http = Axios;
Vue.prototype.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
const token = localStorage.getItem('token');

if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

// Fix leaflet icons import
import L from 'leaflet';
delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('@/assets/factory.png'),
  iconUrl: require('@/assets/factory.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const router = new VueRouter({
  mode: 'history',
  routes: [
    { 
      path: '/', 
      component: Home, 
      name: 'home' , 
      meta: {
        requiresAuth: true
      }
    },
    { 
      path: '/settings', 
      component: Settings, 
      name: 'settings' , 
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
       component: Login, 
       name: 'login' 
    }
  ]
});

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

// router.beforeEach((to, from, next) => {
//   const publicPages = ['/login'];
//   const authRequired = !publicPages.includes(to.path);

//   if (authRequired && !Auth.isAuthenticated()) {
//     next('/login');
//   } else {
//     next();
//   }
// });

new Vue({
  router,
  store,
  vuetify,

  render: h => h(App)
}).$mount('#app')
