<template>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <l-map class="map" style="height: 800px" :zoom="zoom" :center="center" :options="options">
                        <l-tile-layer :url="url"></l-tile-layer>
                        <l-marker v-for="marker in markers" :lat-lng="marker.marker" :key="marker.name">
                            <l-tooltip :content="marker.name"></l-tooltip>
                        </l-marker>

                        <l-polygon
                            v-for="watershed in watersheds" :key="watershed.name"
                            :lat-lngs="watershed.coordinates"
                            color="black"
                            :fillOpacity="0.5" :opacity="0.4"
                            :fillColor="scaleObj[watershed.alert_level]" :weight="1"
                        >
                            <l-tooltip :content="watershed.code + ': ' + watershed.alert_level"></l-tooltip>
                        </l-polygon>
                    </l-map>

                    <div class="map-controls">
                        
                        <v-btn
                            class="map-background map-label"
                            rounded
                            >
                            <v-icon color="green" left>mdi-clock-outline</v-icon>
                            {{ imageDate }}
                        </v-btn>

                    </div>
                    <v-list
                                v-if="scale"
                                class="color-map map-background"
                                dense
                            >
                                <v-list-item>
                                    <v-icon color="red">mdi-alert</v-icon>
                                    <v-spacer />
                                     {{ scaleLabel }}
                                </v-list-item>
                                <v-list-item v-for="item in scale" :key="item.value">
                                    <!-- <v-chip :color="item.color" style="width: 100%" text-color="black">{{ item.value }}</v-chip> -->
                                    <v-icon :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                    <v-spacer />
                                    {{ item.value }} 
                                </v-list-item>
                                
                            </v-list>
                    
                    
                </v-card>
            </v-col>
        </v-row>
</template>

<script>
import moment from 'moment';
import { LMap, LTileLayer, LMarker, LPolygon, LTooltip } from 'vue2-leaflet';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolygon,
    LTooltip
  },
  props: {
      url: String,
      opacity: {
        type: Number,
        default: 0.65
      },
      interval: {
        type: Number,
        default: 1000,
      },
      zoom: Number,
      center: Array,
      markers: Array,
      scale: Array,
      scaleLabel: String,
      watersheds: Array,
      date: String
  },
  data: function () {
    return {
        options: {
            scrollWheelZoom: false,
            attributionControl: false
        }
    }
  },
  methods: {
    colorByLevel: (level) => {
        return this.scale[level];
      },

  },
  computed: {
      scaleObj() {
          let scaleObj = {};
          this.scale.map(item => scaleObj[item.value] = item.color);
          return scaleObj;
      },
      imageDate() {
        if (this.date) {
          return moment.utc(this.date, 'YYYY/MM/DD HH:mm').local().format('DD/MM/YYYY HH:mm');
        }
        return 'Indisponible';
      },
  }
}
</script>

<style scoped>
.map {
    z-index: 0;
}

.map-controls {
  /* background-color: transparent; */
  position: absolute;
  float: right;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.color-map {
  opacity: 0.8;
  position: absolute;
  float: top;
  bottom: 5px;
  left: 5px;
  z-index: 1;
  border-radius: 10px;
  min-width: 110px;
}


.map-label {
  margin: 0 2px 0 2px;
}

.map-background, .map-opacity {
    opacity: 0.75;
    /* background-color: rgba(246, 246, 246, 0.993) !important; */
}

.progress {
    margin-top: 5px;
}

.v-list-item {
    min-height: 25px;
}

</style>
