<template>
  <v-row>
    <v-col cols="12">
      <h2>Alertes</h2>
    </v-col>
    <v-col cols="12" sm="3" v-for="(color, alertLevel) in colors" :key="alertLevel" >
      <v-btn :color="color" dark @click="createAlert(alertLevel)">
        Alerte niveau {{ alertLevel }}
        <v-icon>mdi-alert</v-icon>
      </v-btn>
    </v-col>
                          
    <v-snackbar
      v-model="snack"
      :color="snackColor"
    >
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          
          text
          v-bind="attrs"
          @click="snack = false"
        >
          Fermer
        </v-btn>
      </template>
    </v-snackbar>

  </v-row>

</template>

<script>
import ApiRepository from '@/ApiRepository';


export default {
  name: 'CreateAlert',

  data: () => ({
    snack: false,
    snackColor: '',
    snackText: '',
    colors: {
      1: "#d9c301",
      2: "orange",
      3: "red",
      4: "black"
    }
  }),
  mounted() {
  },
  computed: {

  },
   methods: {
    createAlert(level) {
      ApiRepository.createAlert(level)
        .then(() => {
          this.snackColor = this.colors[level];
          this.snackText = `Alerte de niveau  ${level} envoyee`;
          this.snack = true;
          console.log("Create alert level " + level)
        }).catch(() => {
          this.snackColor = "error";
          this.snackText = "Erreur lors de la creation de l'alerte";
          this.snack = true;
        })



    }
  },
};
</script>
