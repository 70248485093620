<template>

    <div>
        <NavBar />

      <v-container>
        <v-row>
          
          <v-col md="12">
            <v-btn href="/">
            <v-icon>mdi-chevron-left</v-icon>
             Home
            </v-btn>
          </v-col>

          <v-col md="12">
           <user-table></user-table>
          </v-col>
          <v-col md="12">
           <contact-table></contact-table>
          </v-col>

          <v-col md="12">
           <create-alert></create-alert>
          </v-col>
        </v-row>
    </v-container>

    </div>
</template>

<script>
import NavBar from '../components/NavBar';
import ContactTable from '../components/ContactTable';
import UserTable from '../components/UserTable';
import CreateAlert from '../components/CreateAlert';


export default {
  name: 'Settings',

  components: {
    NavBar,
    ContactTable,
    UserTable,
    CreateAlert
  }
};
</script>

<style scoped>
.header {
  margin-top: 50px;
  height: 300px;
}
</style>
