<template>

    <div>
        <NavBar />
      <Alert />

      <v-container>
        <v-row class="text-center">
                <v-col md="6" sm="12">
                    <ImageTabs />
                </v-col>
            <v-col md="6" sm="12">
                <v-card>
                    <AlertsTabs />
                </v-card>
            </v-col>
        </v-row>
        <v-row class="text-center">
            <v-col md="12" sm="12">
              <Cameras />
            </v-col>
        </v-row>
    </v-container>

    <Footer />
    </div>
</template>

<script>
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import Alert from '../components/Alert';
import ImageTabs from '../components/ImagesTabs';
import Cameras from '../components/Cameras';
import AlertsTabs from '../components/AlertsTabs';

export default {
  name: 'Home',

  components: {
    NavBar,
    Footer,
    Alert,
    ImageTabs,
    AlertsTabs,
    Cameras
  },
};
</script>

<style scoped>
.header {
  margin-top: 50px;
  height: 300px;
}
</style>
