<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>


<script>
export default {
  created: function () {
    this.$http.interceptors.response.use(
      response => response,
    // remember to make this async as the store action will 
    // need to be awaited
    (error) => {
      console.log("ERROR")
      let originalRequest = error.config;
      if ([401, 403].includes(error.response.status) && !originalRequest._retry) {
        console.log("FIRST ERROR")
        this.$store.dispatch('refreshToken')
          .then(response => {
              console.log(originalRequest)
              console.log(response)
              originalRequest.headers.Authorization = 'Bearer ' + localStorage.getItem('token')
              return this.$http(originalRequest)
          })
          .catch(err => {
            console.log(err)
            this.$store.dispatch('logout')
              .then(() => {
                this.$router.push('/login')
              })

            return Promise.reject(error);
          })
      }

      // this.$router.push('/login')
      
      
    })
  }
    
}
</script>

<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
  </style>