<template>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <v-img :src="authUrl" width="100%" v-if="directStream" />
                    <v-img v-bind:src="base64Image" width="100%" v-else-if="!directStream && base64Image" />
                     <h3 v-else>Image non disponible</h3>
                     <v-card-actions>
                         <v-card-subtitle class="pb-0">
                        <v-icon left>mdi-cctv</v-icon>
                        {{ title }}
                     </v-card-subtitle>
                        <v-spacer></v-spacer>

                        <v-switch
                            right
                            v-model="directStream"
                            hide-details
                        >
                        <template v-slot:label>
                            <v-icon v-if="directStream">mdi-video-outline</v-icon>
                            <v-icon v-else>mdi-image-outline</v-icon>
                        </template>
                        </v-switch>
                        
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiRepository from '@/ApiRepository';

export default {
    
  components: {
  },
  props: {
      cameraName: String,
      title: String
  },
  data: () => ({
    cameraUrl: process.env.VUE_APP_API_URL + "/camera/stream",
    directStream: false,
    base64Image: null,
    updateIntervalHolder: null
  }),
  mounted() {
      this.getImage();
      clearInterval(this.updateIntervalHolder);
        this.updateIntervalHolder = setInterval(() => {
            if (!this.directStream) {
                this.getImage();
            }
        }, 5000);
  },
  methods: {
      getImage() {
          ApiRepository.getCameraImage(this.cameraName)
            .then(result => {
                this.base64Image = result.data.image;
            })
      }
  },
  computed : {
     
      ...mapGetters([
        'token', 'authToken'
      ]),
        authUrl() {
            return `${this.cameraUrl}?camera=${this.cameraName}&key=${this.token}`
        },
  },
watch: {
    directStream: function (val) {
        if (!val) {
            this.getImage();
        }
    }
  }
}
</script>
