<template>
  <div>
            <h2>Utilisateurs</h2>

                           <v-dialog
      v-model="createContactDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Nouveau
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Nouvel utilisateur</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="newUser.full_name"
                  label="Nom"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="newUser.email"
                  label="Email"
                  type="email"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="newUser.password"
                  label="Mot de passe"
                  type="password"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
              >
                <v-checkbox
                v-model="newUser.is_active"
                  label="Actif"
                ></v-checkbox>
              </v-col>



              <v-col
                cols="12"
                sm="4"
                md="3"
              >
                <v-checkbox
                v-model="newUser.is_superuser"
                  label="Admin"
                ></v-checkbox>
              </v-col>
              
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="createContactDialog = false"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!newUser.full_name ||!newUser.email || !newUser.password"
            @click="createUser"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          
            <v-data-table
              :headers="headers"
              :items="users"
              :items-per-page="-1"
              class="elevation-1"
            >
              <template v-slot:item.full_name="props">
                <v-edit-dialog
                  :return-value.sync="props.item.full_name"
                  @save="save(props.item)"
                >
                  {{ props.item.full_name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.full_name"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.email="props">
                <v-edit-dialog
                  :return-value.sync="props.item.email"
                  @save="save(props.item)"
                >
                  {{ props.item.email }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.email"
                      label="Edit"
                      single-line
                      counter
                      type="email"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.password="props">
                <v-edit-dialog
                  :return-value.sync="props.item.password"
                  @save="save(props.item)"
                >
                  ********
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.password"
                      label="Edit"
                      single-line
                      counter
                      type="password"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.is_active="{ item }">
                <v-simple-checkbox
                  :ripple="false"
                  v-model="item.is_active"
                  :disabled="currentUser.id === item.id"
                  @click="save(item)"
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.is_superuser="{ item }">
                <v-simple-checkbox
                  :ripple="false"
                  v-model="item.is_superuser"
                  :disabled="currentUser.id === item.id"
                  @click="save(item)"
                ></v-simple-checkbox>
              </template>
              
    
              <template v-slot:item.delete="{ item }">

                <v-btn v-if="currentUser.id !== item.id" @click="deleteUser(item)" icon color="red">
                  <v-icon
                  small
                  
                >
                  mdi-delete
                </v-icon>
                </v-btn>
              </template>

            </v-data-table>
            <v-snackbar
              v-model="snack"
              :timeout="3000"
              :color="snackColor"
            >
              {{ snackText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  @click="snack = false"
                >
                  Fermer
                </v-btn>
              </template>
            </v-snackbar>
           

  </div>

</template>

<script>
import ApiRepository from '@/ApiRepository';
import { mapGetters } from 'vuex';


export default {
  name: 'UserTable',

  data: () => ({
    newUser: {'is_active': true},
    createContactDialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    headers: [
          {
            text: 'Nom',
            align: 'start',
            value: 'full_name',
          },
          { text: 'Email', value: 'email' },
          { text: 'Mot de passe', value: 'password' },
          { text: 'Actif', value: 'is_active' },
          { text: 'Admin', value: 'is_superuser' },
          { text: '', value: 'delete' },
        ],
    users: [],
  }),
  mounted() {
    this.loadUsers();
  },
  computed: {
    ...mapGetters({
          currentUser: 'user',
    })
  },
   methods: {
      loadUsers() {
          ApiRepository.getUsers()
            .then(result => {
                this.users = result.data;
            })
            .catch(error => {
                console.log(error);
            })
      },
      save (user) {
        if (!user.password) {
          user.password = null;
        }
        ApiRepository.updateUser(user)
            .then(() => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Utilisateur mis a jour'
            })
            .catch(error => {
                console.log(error);
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Erreur lors de la mise a jour de l\'utilisateur'
                this.loadUsers();
            })
            
      },
      deleteUser(user) {
          ApiRepository.deleteUser(user)
            .then(() => {
                this.snack = true
                this.snackColor = 'warning'
                this.snackText = 'Utilisateur supprime'
                this.loadUsers();
            })
            .catch(error => {
                console.log(error);
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Erreur lors de la suppression de l\'utilisateur'
                
            })
      },
      createUser() {
        ApiRepository.createUser(this.newUser)
            .then(response => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Utilisateur cree avec succes'
                this.loadUsers();
                this.contacts.push(response.data);
                this.newContact = {};
            })
            .catch(() => {
              // if (error.body) {
                this.snackText = 'Erreur lors de la creation de l\'utilisateur'
                this.snackColor = 'error'
                this.snack = true
              // }
               
                
                
            })
        this.createContactDialog = false;

      }
  },
};
</script>
