<template>
    <v-card>
        <v-tabs>
            <v-tab>Instantanées</v-tab>
            <v-tab>Cumul heure précédente</v-tab>

            <v-tab-item class="no-transition">
               <ImageMap 
                    v-if="panthere_sri"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :images="panthere_sri.images"
                    :scale="panthere_sri.scale"
                    :scale-label="panthere_sri.scale_label"
                />
            </v-tab-item>

            <v-tab-item class="no-transition">
                <ImageMap 
                    v-if="panthere_last_hour"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :images="panthere_last_hour.images"
                    :scale="panthere_last_hour.scale"
                    :scale-label="panthere_last_hour.scale_label"
                />
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import ApiRepository from '@/ApiRepository';
import ImageMap from '@/components/maps/ImageMap';

export default {
  name: 'ImageTabs',

  components: {
    ImageMap
  },

  data: () => ({
    url: `${process.env.VUE_APP_API_URL}/tiles/hot?s={s}&z={z}&x={x}&y={y}`,
    zoom: 12,
    center: [45.777222, 3.054025],
    markers: [
        {
            name: 'La Combaude',
            marker: [45.801503, 3.123175],
        },
        {
            name: 'Cataroux',
            marker: [45.791852, 3.102708],
        },
        {
            name: 'Carmes',
            marker: [45.783167, 3.093076],
        },
    ],
    images: {},
    updateIntervalHolder: undefined
  }),
  mounted() {
      this.loadLastHourImages();
      clearInterval(this.updateIntervalHolder);
        this.updateIntervalHolder = setInterval(() => {
            this.loadLastHourImages();
        }, 10000);
      
  },
  beforeDestroy() {
      clearInterval(this.updateIntervalHolder);
  },
  methods: {
      loadLastHourImages() {
          ApiRepository.getLastHourImages()
            .then(result => {
                this.images = result.data;
            })
            .catch(error => {
                console.log(error);
            })
      }
  },
  computed: {
      panthere_sri() {
          if (this.images['panthere_sri']) {
              return this.images['panthere_sri'];
          }

          return undefined;
      },
      panthere_last_hour() {
          if (this.images['panthere_last_hour']) {
              return this.images['panthere_last_hour'];
          }

          return undefined;
      }
  }
};
</script>

<style scoped>
    .no-transition {
        transition: none;
    }
</style>
