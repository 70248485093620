<template>
        <v-row>
            <v-col cols="12">
                <v-card>
                    <l-map class="map" style="height: 800px" :zoom="zoom" :center="center" :options="options">
                        <l-tile-layer :url="url"></l-tile-layer>
                        <l-marker v-for="marker in markers" :lat-lng="marker.marker" :key="marker.name">
                            <l-tooltip :content="marker.name"></l-tooltip>
                        </l-marker>
                        <l-image-overlay v-if="selectedImage && selectedImage.image" :url="selectedImage.image" :bounds="selectedImage.bounds" :opacity="opacity"></l-image-overlay>
                    </l-map>

                    <div class="map-controls" v-if="selectedImage">
                        
                        <v-btn
                            class="map-background map-label"
                            rounded
                            >
                            <v-icon :color="imageStatusColor" left>mdi-clock-outline</v-icon>
                            {{ imageDate }}
                        </v-btn>

                        <span v-if="hasMultipleImages">
                            
                            <v-btn
                                @click="previous"
                                rounded
                                :disabled="!hasPrevious"
                                class="map-background map-label map-opacity"
                            >
                                <v-icon>mdi-chevron-left</v-icon>
                            </v-btn>

                            <v-btn rounded class="map-background map-label map-opacity">
                                        <v-icon v-if="playing" @click="pause">mdi-pause</v-icon>
                                        <v-icon v-else @click="play">mdi-play</v-icon>
                            </v-btn>

                            <v-btn
                            @click="next"
                            rounded
                            :disabled="!hasNext"
                            class="map-background map-label map-opacity"
                            >
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </span>

                        <v-progress-linear v-if="hasMultipleImages" height="10" rounded class="progress map-background" :value="progress" :indeterminate="!selectedImage"></v-progress-linear>


                    </div>
                    <v-list
                                v-if="selectedImage"
                                class="color-map map-background"
                                dense
                            >
                                <v-list-item>
                                    <v-icon>mdi-weather-rainy</v-icon>
                                    <v-spacer />
                                     {{ scaleLabel }}
                                </v-list-item>
                                <v-list-item v-for="item in scale" :key="item.value">
                                    <!-- <v-chip :color="item.color" style="width: 100%" text-color="black">{{ item.value }}</v-chip> -->
                                    <v-icon :color="item.color">mdi-checkbox-blank-circle</v-icon>
                                    <v-spacer />
                                    {{ item.value }} 
                                </v-list-item>
                                
                            </v-list>                    
                </v-card>
            </v-col>
        </v-row>
</template>

<script>
import moment from 'moment';
import { LMap, LTileLayer, LMarker, LTooltip, LImageOverlay } from 'vue2-leaflet';

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip,
    LImageOverlay
  },
  props: {
      url: String,
      opacity: {
        type: Number,
        default: 0.65
      },
      interval: {
        type: Number,
        default: 1000,
      },
      zoom: Number,
      center: Array,
      markers: Array,
      images: Array,
      scale: Array,
      scaleLabel: String
  },
  data: function () {
    return {
        selectedImageIndex: this.images.length - 1,
        playing: false,
        options: {
            scrollWheelZoom: false,
            attributionControl: false
        }
    }
  },
  methods: {
      previous() {
          if (this.hasPrevious) {
              this.selectedImageIndex--;
          } else {
              this.selectedImageIndex = this.images.length - 1;
          }
      },
      next() {
          if (this.hasNext) {
              this.selectedImageIndex++;
          } else {
              this.selectedImageIndex = 0;
          }
      },
      play() {
          if (!this.intervalHolder) {
            this.next();

            this.intervalHolder = setInterval(() => {
                this.next();
            }, this.interval);
        }
        this.playing = true;
      },
      pause() {
        clearInterval(this.intervalHolder);
        this.intervalHolder = null;
        this.playing = false;
      },
  },
  watch: {
      images: function(value) {
          const wasPlaying = this.playing;
          this.pause();
          if (!this.selectedImageIndex) {
              this.selectedImageIndex = value.length - 1;
          }
          if (wasPlaying) {
            this.play();
          }
          
      }
  },
  computed: {
      hasMultipleImages() {
          return this.images.length > 1;
      },
      progress() {
          return 100 / this.images.length * (this.selectedImageIndex + 1);
      },
      selectedImage() {
          return this.images[this.selectedImageIndex];
      },
      hasPrevious() {
          return this.selectedImageIndex > 0;
      },
      hasNext() {
          return this.selectedImageIndex < this.images.length - 1;
      },
      imageStatusColor: function () {
        return this.selectedImage.image ? 'green' : 'red';
      },
      imageDate() {
        if (this.selectedImage.date) {
          return moment.utc(this.selectedImage.date, 'YYYY/MM/DD HH:mm').local().format('DD/MM/YYYY HH:mm');
        }
        return 'Indisponible';
      },
  }
}
</script>

<style scoped>
.map {
    z-index: 0;
}

.map-controls {
  /* background-color: transparent; */
  position: absolute;
  float: right;
  bottom: 10px;
  right: 10px;
  z-index: 1;
}

.color-map {
  opacity: 0.8;
  position: absolute;
  float: top;
  bottom: 5px;
  left: 5px;
  z-index: 1;
  border-radius: 10px;
  min-width: 110px;
}


.map-label {
  margin: 0 2px 0 2px;
}

.map-background, .map-opacity {
    opacity: 0.75;
    /* background-color: rgba(246, 246, 246, 0.993) !important; */
}

.progress {
    margin-top: 5px;
}

.v-list-item {
    min-height: 25px;
}

</style>
