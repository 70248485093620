<template>
    <v-container fluid class="login-form">
        <v-row class="text-center">
            <v-col cols="4" offset="4">
                <v-card>
                    <v-card-title>
                        Alerte Carmes
                    </v-card-title>
                    <v-card-text>

                        <v-form @submit.prevent="login">
                            
                            <v-text-field
                            v-model="email"
                            type="email"
                            label="Identifiant"
                            required
                            ></v-text-field>

                            <v-text-field
                            v-model="password"
                            type="password"
                            label="Mot de passe"
                            required
                            ></v-text-field>

                                <v-card-actions>
                                    <v-spacer/>
                                    <v-btn
                                    type="submit"
                                    :disabled="!(email && password)"
                                    color="success"
                                    class="mr-4"
                                    >
                                        Connexion
                                    </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                    
                </v-card>
            </v-col>
        </v-row>
     </v-container>
</template>

<script>

import Auth from "../Auth";

export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: ''
        }
    },
    mounted() {
        if (Auth.isAuthenticated()) {
            this.$router.push({name: 'home'});
        }
    },
    methods: {
        login() {
            let email = this.email
            let password = this.password
            // console.log(ApiRepository.getAccessToken(this.email, this.password));
            this.$store.dispatch('login', { email, password })
                .then(result => {
                    console.log('Successful login');
                    console.log(result);
                    this.$router.push({name: 'home'});
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
}
</script>

<style scoped>
    .login-form {
        margin-top: 200px;
    }
</style>


