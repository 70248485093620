import ApiRepository from './ApiRepository';

const localStorageKey = 'user';

function parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}


export default {
    isAuthenticated: () => {
        return localStorage.getItem(localStorageKey) != null;
    },
    login: (email, password) => {
        return new Promise((resolve, reject) => {
            ApiRepository.getAccessToken(email, password)
            .then(function(result) {
                console.log(result);
                const accessToken = result.data.access_token;
                const user = {
                    token: accessToken,
                    refreshToken: result.data.refresh_token,
                    username: parseJwt(accessToken).sub
                }
                
                console.log(user);
                
                localStorage.setItem(localStorageKey, JSON.stringify(user));
                console.log('local storage')
                console.log(window.localStorage.getItem(localStorageKey))
                return resolve(true);
            })
            .catch(error => {
                console.log(error);
                return reject(false);
            });
        })
    },
    logout: () => {
        if (localStorage.getItem(localStorageKey)) {
            localStorage.removeItem(localStorageKey);
        }
    },
    getAuthHeaders() {
        const user = localStorage.getItem(localStorageKey);
        
        if (user) {
            return { Authorization: 'Bearer ' + user.token };
        } else {
            return {};
        }
    },
    getUsername() {
        // console.log(localStorage.getItem(localStorageKey).username)
        return JSON.parse(localStorage.getItem(localStorageKey)).username;
    },
    getAuthDetails() {
        // console.log(localStorage.getItem(localStorageKey).username)
        return JSON.parse(localStorage.getItem(localStorageKey));
    }
    
};