import Vue from 'vue'
import Vuex from 'vuex'
// import ApiRepository from './ApiRepository'
// import Auth from './Auth'
import axios from 'axios'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    user: (() => {
        if (localStorage.getItem('user')) {
            return JSON.parse(localStorage.getItem('user'))
        }
        return {}
    })()
  },
  mutations: {
    auth_request(state){
        state.status = 'loading'
      },
      refresh_request(state){
          state.status = 'refreshing'
        },
      auth_success(state, token, refreshToken, user){
        state.status = 'success'
        state.token = token
        state.refreshToken = refreshToken
        state.user = user
      },
      refresh_success(state, token, refreshToken, user){
        state.status = 'success'
        state.token = token
        state.refreshToken = refreshToken
        state.user = user
      },
      auth_error(state){
        state.status = 'error'
      },
      logout(state){
        state.status = ''
        state.token = ''
        state.refreshToken = ''
        state.user = ''
      },
  },
  actions: {
    login({commit}, user){
        return new Promise((resolve, reject) => {
          commit('auth_request')
          axios.post(
            '/login/access-token', 
			new URLSearchParams({username: user.email, password: user.password}),
		)
            .then(resp => {
                const token = resp.data.access_token
                const refreshToken = resp.data.refresh_token
                const user = resp.data.user
                localStorage.setItem('token', token)
                localStorage.setItem('refreshToken', refreshToken)
                localStorage.setItem('user', JSON.stringify(user))
                console.log
                console.log(resp.data)
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                commit('auth_success', token, refreshToken, user)
                resolve(resp)
            })
            .catch(err => {
                console.log(err)
                reject(err)
            })
        })
    },
    refreshToken({commit}) {
        return new Promise((resolve, reject) => {
            commit('refresh_request')
            axios.post(
              '/login/renew-token', 
              {refresh_token: localStorage.getItem('refreshToken')},
          )
              .then(resp => {
                  const token = resp.data.access_token
                  const refreshToken = resp.data.refresh_token
                  const user = resp.data.user
                  localStorage.setItem('token', token)
                  localStorage.setItem('refreshToken', refreshToken)
                  localStorage.setItem('user', JSON.stringify(user))
                  console.log(resp.data)
                  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
                  commit('refresh_success', token, refreshToken, user)
                  resolve(resp)
              })
              .catch(err => {
                  console.log(err)
                  reject(err)
              })
          }) 
    },
    logout({commit}){
        console.log("STORE LOGOUT")
        return new Promise((resolve) => {
          commit('logout')
          localStorage.removeItem('token')
          localStorage.removeItem('refreshToken')
          localStorage.removeItem('user')
          delete axios.defaults.headers.common['Authorization']
          resolve()
        })
      }
  },
  getters : {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    token: state => state.token,
    user: state => state.user,
    isAdmin: state => {
        if (state.user) {
            return state.user.is_superuser
        }
        return false
    }
  }
})