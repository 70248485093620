<template>
        <v-row>
            <v-col md="6" sm="12">
                <CameraStream 
                        camera-name="entree_grille"
                        title='Entrée grille'
                    />
            </v-col>
            <v-col md="6" sm="12">
                 <CameraStream 
                        camera-name="amont_parking"
                        title='Amont Parking'
                    />
            </v-col>
        </v-row>
</template>

<script>
import CameraStream from '@/components/CameraStream';

export default {
  name: 'Cameras',

  components: {
    CameraStream
  },

//   data: () => ({
//     cameraUrl: process.env.VUE_APP_API_URL + "/camera/stream",
//     directStream: false,
//   })
};
</script>

<style scoped>
</style>
