<template>
    <v-card>
        <v-tabs>
            <v-tab>Observation</v-tab>
            <v-tab>+15</v-tab>
            <v-tab>+30</v-tab>
            <v-tab>+45</v-tab>
            <v-tab>+60</v-tab>

            <v-tab-item class="no-transition">
               <PolygonMap 
                    v-if="watershedsNow"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :scale="watershedsNow.scale"
                    :scale-label="watershedsNow.scale_label"
                    :watersheds="watershedsNow.watersheds"
                    :date="watershedsNow.date"
                />
            </v-tab-item>

            <v-tab-item class="no-transition">
               <PolygonMap 
                    v-if="watershedsF15"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :scale="watershedsF15.scale"
                    :scale-label="watershedsF15.scale_label"
                    :watersheds="watershedsF15.watersheds"
                    :date="watershedsF15.date"
                />
            </v-tab-item>

                        <v-tab-item class="no-transition">
               <PolygonMap 
                    v-if="watershedsF30"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :scale="watershedsF30.scale"
                    :scale-label="watershedsF30.scale_label"
                    :watersheds="watershedsF30.watersheds"
                    :date="watershedsF30.date"
                />
            </v-tab-item>

            <v-tab-item class="no-transition">
               <PolygonMap 
                    v-if="watershedsF45"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :scale="watershedsF45.scale"
                    :scale-label="watershedsF45.scale_label"
                    :watersheds="watershedsF45.watersheds"
                    :date="watershedsF45.date"
                />
            </v-tab-item>



            <v-tab-item class="no-transition">
               <PolygonMap 
                    v-if="watershedsF60"
                    :url="url" 
                    :zoom="zoom" 
                    :center="center" 
                    :markers="markers"
                    :scale="watershedsF60.scale"
                    :scale-label="watershedsF60.scale_label"
                    :watersheds="watershedsF60.watersheds"
                    :date="watershedsF60.date"
                />
            </v-tab-item>
        </v-tabs>
    </v-card>
</template>

<script>
import ApiRepository from '@/ApiRepository';
import PolygonMap from '@/components/maps/PolygonMap';

export default {
  name: 'AlertsTabs',

  components: {
    PolygonMap
  },

  data: () => ({
    url: `${process.env.VUE_APP_API_URL}/tiles/hot?s={s}&z={z}&x={x}&y={y}`,
    zoom: 12,
    center: [45.777222, 3.054025],
    markers: [
        {
            name: 'La Combaude',
            marker: [45.801503, 3.123175],
        },
        {
            name: 'Cataroux',
            marker: [45.791852, 3.102708],
        },
        {
            name: 'Carmes',
            marker: [45.783167, 3.093076],
        },
    ],
    // watersheds: {},

    watershedsNow: {},
    watershedsF15: {},
    watershedsF30: {},
    watershedsF45: {},
    watershedsF60: {},
    updateIntervalHolder: undefined
  }),
  mounted() {
      this.loadLastWatershedAlerts();
      clearInterval(this.updateIntervalHolder);
        this.updateIntervalHolder = setInterval(() => {
            this.loadLastWatershedAlerts();
        }, 10000);
      
  },
  beforeDestroy() {
      clearInterval(this.updateIntervalHolder);
  },
  methods: {
      loadLastWatershedAlerts() {
          ApiRepository.getWatershedAlerts()
            .then(result => {
                this.watershedsNow = result.data.now;
                this.watershedsF15 = result.data.f15;
                this.watershedsF30 = result.data.f30;
                this.watershedsF45 = result.data.f45;
                this.watershedsF60 = result.data.f60;
            })
            .catch(error => {
                console.log(error);
            })
      }
  },
  computed: {
    
  }
};
</script>

<style scoped>
    .no-transition {
        transition: none;
    }
</style>
