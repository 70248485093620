<template>
    <nav class="text-center">
      <v-app-bar>
      <img :src="require('@/assets/wm.png')" height="90%" alt="WM logo">

      <v-spacer></v-spacer>

      <v-spacer></v-spacer>

      <img :src="require('@/assets/michelin_2.png')" height="90%" alt="WM logo">
      
        <!-- <v-btn icon @click="darkMode()"><v-icon>mdi-invert-colors</v-icon></v-btn> -->

        <v-btn icon v-if="isAdmin">
            <router-link to="/settings">
            <v-icon
            right
          >
            mdi-cog
          </v-icon>
            </router-link>
          
        </v-btn>
     
        
        <v-btn icon v-if="isLoggedIn" @click="logout">
          <v-icon
            right
          >
            mdi-logout
          </v-icon>
        </v-btn>
 
    </v-app-bar>
    </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
      computed : {
      isLoggedIn : function(){ return this.$store.getters.isLoggedIn },
      ...mapGetters([
        'isLoggedIn', 'isAdmin'
      ])   
    },
    methods: {
        logout: function () {
            this.$store.dispatch('logout')
            .then(() => {
            this.$router.push('/login')
            })
      },
        darkMode() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        },
    }
};
</script>
