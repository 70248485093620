<template>
  <div>
            <h2>Contacts</h2>

                           <v-dialog
      v-model="createContactDialog"
      persistent
      max-width="600px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Nouveau
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Nouveau contact</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="newContact.name"
                  label="Nom"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                  v-model="newContact.email"
                  label="Email"
                  type="email"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                v-model="newContact.phone_number"
                  label="Telephone"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                sm="6"
                md="6"
              >
                <v-text-field
                v-model="newContact.priority"
                  label="Priorite"
                  type="number"
                  value="1"
                  required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <h3>Alertes</h3>
              </v-col>

              <v-col
                cols="12"
                sm="4"
                md="3"
              >
                <v-checkbox
                v-model="newContact.enable_mail"
                :disabled="!newContact.email"
                  label="Email"
                ></v-checkbox>
              </v-col>



              <v-col
                cols="12"
                sm="4"
                md="3"
              >
                <v-checkbox
                v-model="newContact.enable_sms"
                :disabled="!newContact.phone_number"
                  label="SMS"
                ></v-checkbox>
              </v-col>



              <v-col
                cols="12"
                sm="4"
                md="3"
              >
                <v-checkbox
                v-model="newContact.enable_call"
                :disabled="!newContact.phone_number"
                  label="Appel"
                ></v-checkbox>
              </v-col>
              
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="createContactDialog = false"
          >
            Fermer
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="!newContact.name"
            @click="createContact"
          >
            Sauvegarder
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          
            <v-data-table
              :headers="headers"
              :items="contacts"
              :items-per-page="-1"
              class="elevation-1"
            >
              <template v-slot:item.name="props">
                <v-edit-dialog
                  :return-value.sync="props.item.name"
                  @save="save(props.item)"
                >
                  {{ props.item.name }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.name"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.email="props">
                <v-edit-dialog
                  :return-value.sync="props.item.email"
                  @save="save(props.item)"
                >
                  {{ props.item.email }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.email"
                      label="Edit"
                      single-line
                      counter
                      type="email"
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
              <template v-slot:item.phone_number="props">
                <v-edit-dialog
                  :return-value.sync="props.item.phone_number"
                  @save="save(props.item)"
                >
                  {{ props.item.phone_number }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.phone_number"
                      label="Edit"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.enable_mail="{ item }">
                <v-simple-checkbox
                  :ripple="false"
                  v-model="item.enable_mail"
                  :disabled="!item.email"
                  @click="save(item)"
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.enable_sms="{ item }">
                <v-simple-checkbox
                  :ripple="false"
                  v-model="item.enable_sms"
                  :disabled="!item.phone_number"
                  @click="save(item)"
                ></v-simple-checkbox>
              </template>
              <template v-slot:item.enable_call="{ item }">
                <v-simple-checkbox
                  :ripple="false"
                  v-model="item.enable_call"
                  :disabled="!item.phone_number"
                  @click="save(item)"
                ></v-simple-checkbox>
              </template>

              <template v-slot:item.priority="props">
                <v-edit-dialog
                  :return-value.sync="props.item.priority"
                  @save="save(props.item)"
                >
                  {{ props.item.priority }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.priority"
                      label="Edit"
                      single-line
                      type="number"
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>
    
              <template v-slot:item.delete="{ item }">

                <v-btn @click="deleteContact(item)" icon color="red">
                  <v-icon
                  small
                  
                >
                  mdi-delete
                </v-icon>
                </v-btn>
              </template>

            </v-data-table>
            <v-snackbar
              v-model="snack"
              :timeout="3000"
              :color="snackColor"
            >
              {{ snackText }}

              <template v-slot:action="{ attrs }">
                <v-btn
                  v-bind="attrs"
                  text
                  @click="snack = false"
                >
                  Fermer
                </v-btn>
              </template>
            </v-snackbar>
           

  </div>

</template>

<script>
import ApiRepository from '@/ApiRepository';


export default {
  name: 'ContactTable',

  data: () => ({
    newContact: {},
    createContactDialog: false,
    snack: false,
    snackColor: '',
    snackText: '',
    headers: [
          {
            text: 'Nom',
            align: 'start',
            value: 'name',
          },
          { text: 'Email', value: 'email' },
          { text: 'Telephone', value: 'phone_number' },
          { text: 'Mail', value: 'enable_mail' },
          { text: 'SMS', value: 'enable_sms' },
          { text: 'Appel', value: 'enable_call' },
          { text: 'Priorite', value: 'priority' },

          { text: '', value: 'delete' },
        ],
    contacts: [],
  }),
  mounted() {
    this.loadContacts();
  },
  computed: {

  },
   methods: {
      loadContacts() {
          ApiRepository.getContacts()
            .then(result => {
                this.contacts = result.data;
            })
            .catch(error => {
                console.log(error);
            })
      },
      save (contact) {
        if (contact.email === '') {
          contact.email = null;
        }
        console.log(contact)
        ApiRepository.updateContact(contact)
            .then(() => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Contact mis a jour'
            })
            .catch(error => {
                console.log(error);
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Erreur lors de la mise a jour du contact'
                this.loadContacts();
            })
            
      },
      deleteContact(contact) {
          ApiRepository.deleteContact(contact)
            .then(() => {
                this.snack = true
                this.snackColor = 'warning'
                this.snackText = 'Contact supprime'
                this.loadContacts();
            })
            .catch(error => {
                console.log(error);
                this.snack = true
                this.snackColor = 'error'
                this.snackText = 'Erreur lors de la suppression du contact'
                
            })
      },
      createContact() {
        if (this.newContact.email === '') {
          this.newContact.email = null;
        }
        ApiRepository.createContact(this.newContact)
            .then(response => {
                this.snack = true
                this.snackColor = 'success'
                this.snackText = 'Contact cree avec succes'
                this.loadContacts();
                this.contacts.push(response.data);
                this.newContact = {};
            })
            .catch(() => {
              // if (error.body) {
                this.snackText = `Erreur lors de la creation du contact`
                this.snackColor = 'error'
                this.snack = true
              // }
               
                
                
            })
        this.createContactDialog = false;

      }
  },
};
</script>
